.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .react-chatbot-kit-chat-container {
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    overflow: hidden;
    width: 350px;
  }
  
  .react-chatbot-kit-chat-header {
    background-color: #66b032;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .react-chatbot-kit-chat-bot-message,
  .react-chatbot-kit-chat-user-message {
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
  }
  
  .react-chatbot-kit-chat-bot-message {
    background-color: #66b032;
    color: #fff;
  }
  
  .react-chatbot-kit-chat-user-message {
    background-color: #e0e0e0;
    color: #000;
  }
  
  .react-chatbot-kit-chat-input {
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    background-color: #f9f9f9;
  }
  
  .react-chatbot-kit-chat-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
  
  .chatbot-button {
    background-color: #66b032;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001;
  }
  
  .chatbot-button:hover {
    background-color: #e85b00;
  }
  